class ValidacionArray {
  static validarArray (data) {
    const { WhsCode, WhsName, Guia } = data.filter(x => x.WhsCode)[0]
    for (const el of data) {
      if (!(el.WhsCode || el.WhsName)) {
        el.WhsCode = WhsCode
        el.WhsName = WhsName
        el.Guia = Guia
      }
    }
    const yup = require('yup')
    // for (const [index, item] of Object.entries(data)) {
    //   const schema = yup.object().shape({
    //     RowId: yup.number().nullable(),
    //     EnvioId: yup.number().required().label('Número de envío'),
    //     Guia: yup.string().required().label('Número de guía'),
    //     Date: yup.date().nullable().label('Fecha de envío'),
    //     WhsCode: yup.string().nullable().label('Código de bodega'),
    //     WhsName: yup.string().nullable().label('Nombre de bodega'),
    //     ItemCode: yup.string().required().label('Código de producto'),
    //     ItemName: yup.string().required().label('Nombre de producto'),
    //     CodeBars: yup.string().nullable().label('Fecha de envío'),
    //     AgrupId: yup.number().nullable().label('Agrupador'),
    //     IdItemCode: yup.number().required().label('Id de producto'),
    //     NumLote: yup.string().required('El número de lote es requerido').label('Número de lote').typeError('El número de lote es requerido'),
    //     Quantity: yup.number().required('La cantidad es requerida').label('Cantidad').typeError('La cantidad es requerida'),
    //     FecVen: yup.date().required('La fecha de vencimiento es requerida').label('Fecha de vencimiento').typeError('La fecha de vencimiento es requerida'),
    //     Status: yup.boolean().required()
    //   })
    //   return schema.validate(item)
    //   .catch((err) => {
    //     return {
    //       error: err.errors,
    //       index: index
    //     }
    //   })
    // }

    const schema = yup.object().shape({
      RowId: yup.number().nullable(),
      EnvioId: yup.number().required().label('Número de envío'),
      Guia: yup.string().required().label('Número de guía'),
      Date: yup.date().nullable().label('Fecha de envío'),
      WhsCode: yup.string().nullable().label('Código de bodega'),
      WhsName: yup.string().nullable().label('Nombre de bodega'),
      ItemCode: yup.string().required().label('Código de producto'),
      ItemName: yup.string().required().label('Nombre de producto'),
      CodeBars: yup.string().nullable().label('Fecha de envío'),
      AgrupId: yup.number().nullable().label('Agrupador'),
      IdItemCode: yup.number().required().label('Id de producto').when('Status', (Status, schema) => {
        if (Status) {
          return schema.required().typeError('El IdItemCode es requerido')
        } else {
          return schema.nullable(true)
        }
      }),
      NumLote: yup.string().label('Número de lote').when('Status', (Status, schema) => {
        if (Status) {
          return schema.required().typeError('El número de lote es requerido')
        } else {
          return schema.nullable(true)
        }
      }),
      Quantity: yup.number().required('La cantidad es requerida').label('Cantidad').when('Status', (Status, schema) => {
        if (Status) {
          return schema.required().typeError('La cantidad es requerida')
        } else {
          return schema.nullable(true)
        }
      }),
      FecVen: yup.date().required('La fecha de vencimiento es requerida').label('Fecha de vencimiento').when('Status', (Status, schema) => {
        if (Status) {
          return schema.required().typeError('La fecha de vencimiento es requerido')
        } else {
          return schema.nullable(true)
        }
      }),
      Status: yup.boolean().required()
    })
    const arraySchema = yup.array().of(schema)
    return arraySchema.validate(data).catch((err) => {
      console.dir(err, { depth: null, colors: true })
      return {
        error: err.message,
        index: JSON.parse(err.path.split('.')[0])[0]
      }
    })
  }
}

module.exports = ValidacionArray
