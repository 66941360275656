<template>
  <DataTable v-model:value="rows" class="p-datatable-small" tableStyle="min-width: 50rem">
    <Column header="Articulo">
      <template #body="{ data }">
        <div class="flex gap-4">
          <div class="flex flex-col">
            <span class="ml-2 pi pi-plus-circle cursor-pointer text-blue-800 my-auto" v-tooltip="'Agregar ov'" @click="openModalOv(data)" />
            <span class="ml-2 pi pi-trash cursor-pointer text-red-800 my-auto" v-tooltip="'Remover de apartados'" @click="EliminarMv(data)" />
          </div>
          <div class="flex flex-col" style="font-size: 0.7rem; ">
            <div class="flex">
              <strong class="mr-2">Codigo:</strong>
              <span class="underline text-blue-600 cursor-pointer" v-tooltip="'Agregar ov'" @click="openModalOv(data)">{{data.ItemCode}}</span>
            </div>
            <div class="flex">
              <strong class="mr-2">Descripcion:</strong>
              {{ data.ItemName }}
            </div>
            <div class="flex">
              <div>
                <strong class="mr-2">Cantidad:</strong>
                {{data.Quantity}}
              </div>
            </div>
          </div>
        </div>
      </template>
    </Column>
    <Column header="Orden de venta">
      <template #body="{data}">
        <div class="list-ov  overflow-auto hover:overflow-scroll" style="font-size: 0.7rem; max-height: 3.5rem;">
          <ul class="space-y-4">
            <li class="flex"  v-for="(apt ,index) in data.Apartados" :key="index">
              <strong class="mr-2">Ov:</strong>
              <div class="flex">
                {{apt.OvId}}
                <strong class="mx-2">Medicamento:</strong>
                {{apt.OvItemCode}}
                <strong class="mx-2"> Cantidad:</strong>
                {{apt.Cantidad}}
                <span class="ml-2 pi pi-trash cursor-pointer text-red-800 my-auto" @click="onRemoveApartado(apt)" />
              </div>
            </li>
          </ul>
        </div>
      </template>
    </Column>
  </DataTable>
  <div class="flex justify-end mt-5 gap-2">
    <Button label="Cancelar" severity="danger" @click="onCancelar" />
    <Button label="Confirmar" @click="confirmarApartados"/>
  </div>
  <Dialog v-model:visible="visibleDialog" header="Agregar orden de venta" :style="{ width: '50vw' }">
    <form class="space-y-6">
      <div class="grid grid-cols-2 gap-2">
        <div>
          <label for="numOv" class="block text-sm font-medium leading-6 text-gray-900">Numero de orden de venta</label>
          <div class="mt-2">
            <div class="p-inputgroup flex-1">
              <InputText v-model="formOv.OvId" class="text-xs" size="small" style="border-bottom-right-radius: 0px !important; border-top-right-radius: 0px !important;" />
              <Button icon="pi pi-search" size="small" @click="getOvDtId" />
            </div>
          </div>
        </div>
        <div>
          <label for="Quantity" class="block text-sm font-medium leading-6 text-gray-900">Cantidad</label>
          <InputNumber v-model="formOv.Quantity" class="w-full mt-2 p-component p-inputtext-sm" size="small"  />
          <span class="text-xs text-gray-500">Cant. Pendiente: {{cantidadPendiente}}</span>
        </div>
      </div>
      <div>
        <label class="block text-sm font-medium leading-6 text-gray-900">Articulo/Descripción</label>
        <div class="mt-2">
          <Dropdown
            v-model="formOv.OvDtId"
            :options="optionsDropDown"
            optionLabel="ItemName"
            filter
            class="w-full p-component p-inputtext-sm"
            size="small"
            @change="onChange($event)"
          />
        </div>
<!--        option-value="OvDtId"-->
      </div>
      <div class="flex justify-end gap-2">
        <Button label="Confirmar" text raised @click="onAddApartado"/>
        <Button label="Cancelar"  severity="danger" text raised @click="openModalOv"/>
      </div>
    </form>
  </Dialog>
  <Toast />
  <ConfirmPopup/>
</template>
<script setup>
import groupBy from 'lodash/groupBy'
import { onMounted, reactive, ref, defineProps } from 'vue'
import { useCrear } from '../../../store/crear.store'
import { useConfirm } from 'primevue/useconfirm'
import { useToast } from 'primevue/usetoast'
import { useRouter } from 'vue-router'
import sumBy from 'lodash/sumBy'
import Swal from 'sweetalert2'
const props = defineProps({
  header: {
    type: Object,
    required: true
  }
})
const toast = useToast()
const confirm = useConfirm()
const router = useRouter()
const storeCrear = useCrear()
const rows = ref([])
const cantidadPendiente = ref(0)
onMounted(() => {
  const listMds = JSON.parse(localStorage.getItem('confirmarRecepcion')).filter(x => x.Status === true)
  for (const argument of Object.values(groupBy(listMds, 'ItemCode'))) {
    rows.value.push({
        ItemCode: argument[0].ItemCode,
        ItemName: argument[0].ItemName,
        IdItemCode: argument[0].IdItemCode,
        Quantity: argument.reduce((a, b) => a + parseInt(b.Quantity), 0),
        Apartados: []
    })
  }
})
/** modal ***/
const visibleDialog = ref(false)
const formOv = reactive({
  OvId: null,
  Quantity: null,
  OvDtId: null,
  ItemCode: null,
  EntItemCode: null,
  IdItemCode: null
})
const optionsDropDown = ref([])
const openModalOv = ({ ItemCode, IdItemCode }) => {
  visibleDialog.value = !visibleDialog.value
  cantidadPendiente.value = 0
  formOv.OvId = null
  formOv.Quantity = 0
  formOv.OvDtId = null
  formOv.ItemCode = ItemCode ?? null
  formOv.IdItemCode = IdItemCode ?? null
  optionsDropDown.value = []
}
const getOvDtId = async () => {
  optionsDropDown.value = await storeCrear.getOvDtId(formOv.OvId)
}
const validateQuantitySeparated = async (index) => {
  const totalApartado = sumBy(rows.value[index].Apartados, 'Cantidad')
  const totalItem = rows.value[index].Quantity
  const suma = totalApartado + formOv.Quantity
  return totalItem >= suma
}
const onAddApartado = async () => {
  const index = rows.value.findIndex(x => x.ItemCode === formOv.ItemCode)
  if (!await validateQuantitySeparated(index)) {
    toast.add({ severity: 'error', summary: 'Error', detail: 'La cantidad apartada no puede ser mayor a la cantidad recibida', life: 3000 })
    return
  }
  if (cantidadPendiente.value < formOv.Quantity) {
    toast.add({ severity: 'error', summary: 'Error', detail: 'La cantidad apartada no puede ser mayor a la cantidad pendiente', life: 3000 })
    return
  }
  rows.value[index].Apartados.push({
    OvId: formOv.OvId,
    Cantidad: formOv.Quantity,
    OvDtId: formOv.OvDtId.OvDtId,
    ItemCode: formOv.ItemCode,
    OvItemCode: optionsDropDown.value.find(x => x.OvDtId === formOv.OvDtId.OvDtId).ItemCode ?? null,
    IdItemCode: formOv.IdItemCode,
    WhsCode: props.header.WhsCode ?? null
  })
  openModalOv({})
}
const EliminarMv = ({ ItemCode }) => {
  confirm.require({
    target: event.currentTarget,
    message: 'Remover apartado. Estas seguro que deseas continuar?',
    icon: 'pi pi-exclamation-triangle',
    accept: () => {
      rows.value = rows.value.filter(x => x.ItemCode !== ItemCode)
    }
  })
}
const onRemoveApartado = ({ ItemCode, OvDtId }) => {
  const index = rows.value.findIndex(x => x.ItemCode === ItemCode)
  rows.value[index].Apartados = rows.value[index].Apartados.filter(x => x.OvDtId !== OvDtId)
}
const confirmarApartados = () => {
  const apartados = rows.value.flatMap(x => x.Apartados)
  storeCrear.confirmarApartados(apartados).then(async (data) => {
    return Swal.fire({
      title: 'Registro exitoso',
      text: 'Se registro la recepcion tecnica con exito',
      icon: 'success'
    }).then(async () => {
      await router.push({ name: 'pharmasan.inventario' })
      localStorage.removeItem('confirmarRecepcion')
    })
  })
}
const onCancelar = () => {
  localStorage.removeItem('confirmarRecepcion')
  router.go(-1)
}
const onChange = ({ value }) => {
  cantidadPendiente.value = value.Pendiente
}
</script>
<style scoped>
</style>
