/* eslint-disable no-useless-return */
import { defineStore } from 'pinia'
import EntradaMercancia from '../services/entradaMercancia'
import ValidacionArray from '../schemas/EntMercancia.schema'

const _EntradaMercancia = new EntradaMercancia()
export const useCrear = defineStore('Crear', {
  state: () => {
    return {
      EntMercancia: [],
      Apartados: []
    }
  },
  getters: {
    _entMercancia: (state) => state.EntMercancia,
    _apartados: (state) => state.Apartados
  },
  actions: {
    validar () {
      const data = JSON.parse(localStorage.getItem('confirmarRecepcion'))
      return ValidacionArray.validarArray(data).then((valid) => {
        return valid
      })
    },
    registartEntMercancia () {
      return new Promise((resolve, reject) => {
        const data = JSON.parse(localStorage.getItem('confirmarRecepcion'))
        ValidacionArray.validarArray(data)
          .then(async (validatedData) => {
            if (!validatedData.error) {
              _EntradaMercancia.registrar({ EntMercancia: validatedData, Apartados: this.Apartados }).then(({ data }) => {
                localStorage.removeItem('confirmarRecepcion')
                resolve(data)
              })
            } else {
              reject(validatedData)
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getOvDtId (DocId) {
      return new Promise((resolve, reject) => {
        _EntradaMercancia.getOvDtId(DocId).then(({ data }) => {
          resolve(data)
        }).catch((err) => {
          console.log(err)
          // eslint-disable-next-line prefer-promise-reject-errors
          reject([])
        })
      })
    },
    async confirmarApartados (payload) {
      this.Apartados = payload
      return this.registartEntMercancia()
    }
  }
})
